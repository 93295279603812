import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  iframe: {
    flexGrow: 1,
    border: 'none',
  },
  overlay: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: 'rgba(250, 250, 250, 0.8)',
    // height: 1,


    position: 'fixed',
    top: 0,
    right: 0,
    width: '220px',
    height: '67px',
    backgroundColor: 'rgb(250,250,250 )', /* White with 80% opacity */
    zIndex: 9999, /* Ensure overlay is above iframe */
  
    display: 'flex',
    alignItems: 'center',
  },
  overlayText: {
    // color: 'black',
    // fontSize: 28,
    // fontWeight: 'bold',
    // fontFamily: 'Gotham, sans-serif',

    color: 'black', /* Set text color */
      fontSize: '28px',
      fontWeight: 'bold',
      // font-family: "Gotham", sans-serif, 
  },
}));

const GISMap = () => {
  const classes = useStyles();
 
const func = () =>{
  console.log('asds');
  var checkFieldInterval = setInterval(function() {
  console.log(localStorage.getItem('farm')?localStorage.getItem('farm'):'something');

  // document.getElementsByClassName('jfk-textinput label-input-label')[0].value ='asdas'
    
  }, 1000);
  return (
  <script>
  </script>)
}

  return (
    <div className={classes.root} onLoad={func}>
      <iframe
        id='earth'
        className={classes.iframe}
        src="https://ee-limspak9.projects.earthengine.app/view/newapp"
        frameborder="0"
        title='Map'
      />
      <div className={classes.overlay}>
        <div className={classes.overlayText}>
          <pre></pre>
        </div>
      </div>
    </div>
  );
};

export default GISMap;
