import React,{useState} from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import {Card, Grid } from "@mui/material";
import useWindowDimensions from "../hooks/getScreenDimensions"; 
import Lightbox from "yet-another-react-lightbox";
 
import "yet-another-react-lightbox/styles.css";
import { KISSAN_01, KISSAN_02, KISSAN_03 } from "../utils/constants";
const kissanCard = [
  {
    id: 1,
    image : KISSAN_01
  },
  {
    id: 2,
    image : KISSAN_02
  },
  {
    id: 3,
    image : KISSAN_03
  } 
];
export default function KissanCorner() {
  const [isOpen, setIsOpen] = useState(false);
  const handlePopUpOpen = () => {
    setIsOpen(true);
  };
    const {width} = useWindowDimensions()
 
    const options = {
        margin: 10,
        responsive: {
          0: { items: 1, dots: false },
          500: { items: 2, dots: false },
          700: { items: 3, dots: false },
          1050: { items: 6 },
        },
      };
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}></Grid>
      <Grid item xs={10} sx={{
        display: 'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
      }}>
        <OwlCarousel className="owl-theme" {...options}>
          {kissanCard.map((card, index) => (
            <div key={card.id} className="item" style={{ cursor: "pointer" }} onClick={handlePopUpOpen}>
              <Card style={{
                height: width < 800 ? '400px' : (
                  width < 1800 ? '350px' : (
                    width < 2500 ? '400px' : '600px'
                  )
                )
              }}>
                <img
                  src={card.image}
                 alt={`${card.id}`}
                  style={{
                    width: "100%",
                    height: width < 800 ? '400px' : (
                      width < 1800 ? '350px' : (
                        width < 2500 ? '400px' : '600px'
                      )
                    )
                  }}
                />
              </Card>
            </div>
          ))}
        </OwlCarousel>

        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={[
             
            { src: KISSAN_01 }, { src: KISSAN_02 }, { src: KISSAN_03 } 
          ]}
        />
      </Grid>
      </Grid>
    </>
  );
}