import React, { useState, useEffect } from 'react';
import * as L from 'leaflet';
import { Grid, Typography, Alert } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import { GeoSearchControl, OpenStreetMapProvider, EsriProvider } from 'leaflet-geosearch';
import LeafletgeoSearch from './LeafletgeoSearch';
import MapControls from './MapControls';
import { Modal, Button, Drawer, Space } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Tour from 'reactour';
// import Title from '../utils/Title';
import { useNavigate } from 'react-router-dom';
import './font.css';
import parse from 'html-react-parser';
// import AdvisoryGraph from './testGraph';
import SideBar from './sidebar';
import 'leaflet-geosearch/dist/geosearch.css';

const position = [30.33084, 71.2475];

export const AdvisoryModal = NiceModal.create(({ location }) => {
  const modal = useModal();
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const handleFormSubmit = (condition) => {
    if (condition) {
      setMessage('Message sent successfully');
      setSeverity('success');
    } else {
      setMessage('Message sent failed. Please try again after 1 minute');
      setSeverity('error');
    }
    setFormSubmitted(true);
  };
  useEffect(() => {
    if (isFormSubmitted) {
      const timeout = setTimeout(() => {
        setFormSubmitted(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [isFormSubmitted]);

  console.log('KML', location);
  return <></>;
});

const MapView = () => {
  const steps1 = [];

  const steps = [];

  const [isTourOpen, setIsTourOpen] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {console.log(123)}, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('Password:', password);
  };

  return (
    <>
      {/* <Title title={'Advisory Service'} />
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: '10px',
          padding: '10px',
          background: '#31313196'
        }}
      >

        <ArrowBackIcon
          style={{
            color: 'white',
            fontSize: '32px',
            marginRight: '5px'
          }}
        />

        <span onClick={goBack} style={{ color: 'white', fontSize: '18px' }}>Go Back</span>
      </div> */}
      <MapContainer
        center={position}
        zoomControl={false}
        zoom={7}
        scrollWheelZoom={true}
        style={{ minHeight: '100vh', maxWidth: '100vw' }}
      >
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
        {/* <ZoomControl position="bottomright" /> */}
        <LeafletgeoSearch className="step-1" />
        <MapControls />
      </MapContainer>
      <div
      // style={{
      //   top: '10px',
      //   left: '10px',
      //   zIndex: 1001,
      //   // width: '100vh',
      //   height: 441
      // }}
      >
        {/* <AdvisoryGraph /> */}
      </div>
      <div></div>
      {/* <Tour
        backdrop={"static"}
        steps={steps}
        onRequestClose={() => setIsTourOpen(false)}
        closeWithMask={false}
        isOpen={isTourOpen} /> */}
    </>
  );
};

export default MapView;
