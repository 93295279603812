import * as L from 'leaflet';
import { useLeafletContext } from '@react-leaflet/core';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import React, { useState, useEffect, useRef } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import tokml from 'tokml';
import { useNavigate } from 'react-router-dom';

const MapControls = ({ modal }) => {
  const context = useLeafletContext();

  useEffect(() => {
    const leafletContainer = context.layerContainer || context.map;

    leafletContainer.pm.addControls({
      position: 'topright',
      drawPolyline: false,
      drawMarker: false,
      drawCircle: false,
      drawCircleMarker: false,
      drawText: false,
      editable: false,
    });

    leafletContainer.pm.setGlobalOptions({ pmIgnore: false });

    leafletContainer.on('pm:create', (e) => {
      if (e.layer && e.layer.pm) {
        const shape = e;
        // console.log(e);
        shape.layer.pm.enable();
        //console.log(object created: ${shape.layer.pm.getShape()});
        //  console.log(e.layer.toGeoJSON())
        // console.log(
        //   leafletContainer.pm.getGeomanLayers(true).toGeoJSON().features[0].geometry.coordinates
        // );
        var kml = tokml(leafletContainer.pm.getGeomanLayers(true).toGeoJSON());
        localStorage.setItem(
          'FarmKML',
          JSON.stringify(
            leafletContainer.pm.getGeomanLayers(true).toGeoJSON().features[0].geometry.coordinates
          )
        );
        console.log(localStorage.getItem('FarmKML'));
        console.log(leafletContainer.pm.getGeomanLayers(true).toGeoJSON().features);
        // console.log(leafletContainer.pm.getGeomanLayers(true).toGeoJSON().features.reverse());

        const blob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });
        const file = new File([blob], 'farm.kml', { type: 'application/vnd.google-earth.kml+xml' });

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.download = 'farm.kml';
        downloadLink.href = URL.createObjectURL(file);

        // Trigger the download
        downloadLink.click();
        console.log('idr bhi');
        // useNavigate(-1);

        //leafletContainer.removeLayer(e.layer);
        // NiceModal.show(modal,{ location: file })
        // leafletContainer.pm
        //   .getGeomanLayers(true)
        //   .bindPopup("i am whole")
        //   .openPopup();
        // leafletContainer.pm
        //   .getGeomanLayers()
        //   .map((layer, index) => layer.bindPopup(I am figure N° ${index}));
        // shape.layer.on("pm:edit", (e) => {
        //   const event = e;
        //   // console.log(leafletContainer.pm.getGeomanLayers(true).toGeoJSON());
        // });
      }
    });

    leafletContainer.on('pm:remove', (e) => {
      console.log('object removed');
    });

    return () => {
      leafletContainer.pm.removeControls();
      leafletContainer.pm.setGlobalOptions({ pmIgnore: true });
    };
  }, [context]);
  console.log('done');
  return null;
};

export default MapControls;
