import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CompleteHome from "./components/home/CompleteHome";
import CompleteAbout from "./components/about/CompleteAbout";
import CompleteContact from "./components/contact/CompleteContact";
import CropMonitoring from "./components/monitoring/CropMonitoring";
import GISMap from "./components/GISMap";
import MapView from "./components/advisory/Mapview";
import NotFound from "./components/utils/notFound";
import CompleteCareer from "./components/careers/CompleteCareer";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CompleteHome />} />
        <Route path="/home" element={<CompleteHome />} />
        <Route path="/crop-monitoring" element={<CropMonitoring />} />
        <Route path="/about" element={<CompleteAbout />} />
        <Route path="/contact" element={<CompleteContact />} />        
        <Route path="/GISMap" element={<GISMap />} />        
        <Route path="/MakeKML" element={<MapView />} />        
        <Route path="Career" element={<CompleteCareer/>}/>      
        <Route path="/404" element={<NotFound />} />  
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
