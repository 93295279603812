import * as L from 'leaflet';
export { default as INTRO_VIDEO } from "../../public/videos/intro.mp4";
export { default as WEATHER_MAPS } from "../../public/images/weather_maps.png";
export { default as SOIL } from "../../public/images/soil.jpg";
export { default as VEGETATION_IMAGE } from "../../public/images/vegetation_and_monitoring.jpeg";
export { default as SMART_SOLUTIONS } from "../../public/images/SmartSolutions.png";
export { default as KISSAN_01 } from "../../public/kissan/kissan01.jpeg";
export { default as KISSAN_02 } from "../../public/kissan/kissan02.jpeg";
export { default as KISSAN_03 } from "../../public/kissan/kissan03.jpeg";
export { default as BACKGROUND_WHOLE } from "../../public/images/background_whole.jpg";
export { default as WHITE_LOGO } from "../../public/images/white_logo.svg";
export { default as COLORED_LOGO } from "../../public/images/logo.png";
export { default as COTTON } from "../../public/images/Cotton.jpg";
export { default as LAND_CONS } from "../../public/images/LandCons.jpg";
export { default as DAM } from "../../public/images/dam.png";
export { default as PEST_SCOUTING } from "../../public/images/PestScouting.png";
export default L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
export const BASE_URL = "https://limspakistan.com/admin/api";
export const END_POINT = "/contactUs";
export const capSiteKey = "6LcU3lgoAAAAANQJflu8EL_EmVZWGIoZirN67Ag2"