import * as L from 'leaflet';

export const BASE_URL = "https://limspakistan.com/admin/api";
export const END_POINT ="/contactUs";
export const registerFarm ="/registerFarm";
export const getUserFarm ="/getUserFarm";
export const capSiteKey = "6LcU3lgoAAAAANQJflu8EL_EmVZWGIoZirN67Ag2"

export default L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
