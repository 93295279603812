import { Typography } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";

import Title from "../utils/Title";

export default function Main() {
 
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <>
      <Container fluid>
      <Title title={'About Us'}/>
        <Row style={{marginTop:'30px'}}>
          <Col xs={12}>
            <Typography align="center" mt="10px" variant="h5">
              <strong>Who We Are </strong>
            </Typography>
            
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
               At the heart of LIMS is a team of experienced professionals,
                each with a deep-rooted commitment to advancing the field of
                food security. Our team comprises experts in various
                disciplines, including human resource development, agronomy,
                data science, remote sensing, and software engineering. With a
                shared vision, we work tirelessly to develop solutions that
                empower farmers, agronomists, and stakeholders to make informed
                decisions and drive positive change.
            </p>
          </Col>
        </Row>
        <Row >
          <Col xs={12}>
            <Typography align="center" mt="30px" variant="h5">
              <strong>Our Mission </strong>
            </Typography>
            
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              Our mission is “to ensure food security and optimize agricultural
              production in Pakistan through innovative technologies and
              sustainable precision agricultural practices based on
              agro-ecological potential of land, while ensuring wellbeing of
              rural communities and preservation of the environment”.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
            <strong >Our Approach</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              We understand modern agriculture's challenges – from optimizing
              resource utilization to mitigating the impacts of climate change.
              That's why our approach combines state-of-the-art technology with
              a profound understanding of agricultural practices. We provide
              actionable insights that enable precision agriculture and
              sustainable land management by seamlessly integrating satellite
              data, sensor technologies, and advanced analytics.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>Our Values</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col
            sx={12}
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Typography>
              <strong>Insightful</strong>
            </Typography>
            <ul
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
              }}
            >
              <li>
                Leadership in Agriculture. COE will become a flag bearer and
                will be placed in a leadership role in the field of optimized
                agriculture.
              </li>
              <li>
                Optimal Use of Land. Land shall be used optimally with minimal
                wastage for maximum production ratios.
              </li>
              <li>
                Environment. COE shall accord high priority to the preservation
                and improvement of the environment through the latest
                agriculture practices.
              </li>
            </ul>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col
            sx={12}
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Typography>
              <strong>Impactful</strong>
            </Typography>
            <ul
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
              }}
            >
              <li>
                Outcome Driven. Targets and goals will be set for all areas of
                focus.
              </li>
              <li>
                Constructive. Strategy and plans will be constructive in nature.
              </li>
              <li>
                Practical & Useful. Agricultural practices shall be practical,
                doable, and useful.
              </li>
              <li>
                Teamwork. Centre of Excellence (COE) will function on the
                principle of teamwork.
              </li>
            </ul>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col
            sx={12}
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Typography>
              <strong>Inspiring</strong>
            </Typography>
            <ul
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
              }}
            >
              <li>
                Centre of Excellence (COE) shall be a unique entity in
                optimization in agricultural production, which will become an
                inspiration for others to follow. Policies and implementation
                strategies will be formulated with imagination and creativity.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>What we do</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col
            sx={12}
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Typography>
              <strong>Consolidation of Land through</strong>
            </Typography>
            <ul
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
              }}
            >
              <li>
                GIS-based land information management system of the state lands
              </li>
              <li>
                Identification of optimal strategies for land utilization
                (towards building a master plan)
              </li>
            </ul>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col
            sx={12}
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Typography>
              <strong>Agricultural Production/corporate farming</strong>
            </Typography>
            <ul
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
              }}
            >
              <li>
                Digitalization of agriculture for supporting best practices
                through web and mobile technologies (to support optimal
                utilization of inputs and outputs).
              </li>
              <li>
                Land evaluation of the cultivable land as per agro-ecological
                potential (to identify what to grow and where to grow).
              </li>
              <li>
                Development of Agricultural Monitoring System using
                state-of-the-art satellite and drone remote sensing and
                geospatial technologies (to support how to grow/cultivate).
              </li>
              <li>
                Development of Weather Monitoring and Analysis System (to
                support how to grow/cultivate).
              </li>
              <li>
                Development of a state-of-the-art Decision Support System for
                agriculture.
              </li>
              <li>
                To Increase Crop Yields and to bridge the gap between food
                import and export.
              </li>
              <li>
                Practicing Best Agri Practices (to maximize production while
                minimizing inputs and conserving the environment).
              </li>
              <li>To demonstrate Climate-Smart Agriculture.</li>
              <li>
                To develop an effective and efficient Marketing System by
                minimizing the role of middleman.
              </li>
              <li>
                Supporting subsistence farming communities in the neighborhood
                with technology.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>Join us in our journey towards sustainable development of Pakistan</strong>
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "15px",
                lineHeight: "27px",
                textAlign: "center",
              }}
            >
              Whether you're a farmer seeking to optimize your yields, a
              researcher pushing the boundaries of agricultural science, or a
              stakeholder invested in the future of food security, we invite you
              to join us on our journey. Together, we can shape a future where
              technology and agriculture work hand in hand to create a better
              world. For inquiries, collaborations, or to learn more about our
              solutions, please   <Link to={`/contact`}> Contact Us </Link> Thank you for choosing LIMS as your
              partner in agricultural innovation
            </p>
          </Col>
        </Row>
        <Row>
          <Col sx={12}>
            <Typography align="center" variant="h5" mt="50px">
              <strong>ISMS Policy</strong>
            </Typography>
          </Col>
        </Row>
        <br></br>
        <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Col sx={12} style={{ display: "flex", justifyContent: "center" }}>
          <ul
           style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "15px",
            lineHeight: "27px",
          }}>
      
      <li>To ensure confidentiality, integrity, and availability requirements of LIMS-COE  customers, stakeholders and interested parties whenever possible, meeting and exceeding their expectations.</li>
        <li>To make the details of LIMS-COE  policies known to all other interested parties including external parties, where appropriate.</li>
        <li>To ensure that LIMS-COE  maintains its awareness for continual improvement using risk-based approach, and the ISMS is regularly reviewed by leadership to ensure it remains appropriate and suitable to LIMS  business. The ISMS is subject to both internal and external annual audits.</li>
        <li>Provide trained and competent staff and any other resource requirements to meet the ISMS objectives.</li>
        <li>Comply with all legal requirements, codes of practice and other requirements applicable to LIMS-COE  activities.</li>
        <li>Ensure the information security of all the resources is compliant with the ISMS.</li>
        <li>Ensure that all employees are made aware of their individual obligations in respect of information security policy.</li>
        <li>All the information security controls are driven by business needs, and this will be regularly communicated to all staff through team meetings and briefing documents.</li>
        <li>Information security objectives will be documented for an agreed time, together with details of how they will be achieved.</li>

      </ul>

          </Col>
        </Row>
        
      </Container>
    </>
  );
}